<template>
  <div class="allBillPage">
    <!-- <el-breadcrumb separator="/" style="margin: 10px 0px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>所有运单</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 表格区域 -->
    <div style="max-height: 84.5vh">
      <!-- 温馨提示 -->
      <!-- <el-alert style="margin-bottom: 8px;" type="warning" description="待办理和已完成的所有运单，供筛选和下载" :closable="false">
      </el-alert> -->
      <div class="topbtns">
        <el-button icon="el-icon-download" :disabled="loading" type="primary" size="medium" @click="exportExcel">导出表格
        </el-button>
      </div>
      <el-tabs v-model="searchForm.type" @tab-click="changeType">
        <el-tab-pane v-for="item in sceneList" :key="item.Code" :label="item.Name" :name="item.Code"></el-tab-pane>
      </el-tabs>
      <el-form class="searchForm" :model="searchForm" ref="searchForm">
        <div style="display: flex; flex-wrap: wrap">
          <!-- <el-form-item label="业务类型" prop="keyword" label-width="140px">
            <el-select @change="changeType" v-model="searchForm.type" placeholder="请选择业务类型" style="width: 180px">
              <el-option v-for="item in sceneList" :key="item.Code" :label="item.Name" :value="item.Code">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="进度状态：" prop="keyword" label-width="140px">
            <el-select v-model="searchForm.status" placeholder="请选择进度状态" style="width: 180px">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="司机手机号：" prop="keyword" label-width="140px">
            <el-input @keyup.enter.native="search" style="width: 180px" v-model="searchForm.driverPhone"
              placeholder="请输入司机手机号">
            </el-input>
          </el-form-item>

          <el-form-item label="司机身份证号：" prop="DriverIdCard" label-width="140px">
            <el-input style="width: 180px" v-model="searchForm.DriverIdCard" placeholder="请输入司机身份证号">
            </el-input>
          </el-form-item>
          <el-form-item prop="keyword">
            <div style="display: flex">
              <el-dropdown @command="handleCommandName" trigger="click" placement="bottom">
                <span class="el-dropdown-link">
                  <div style="
                      width: 108px;
                      text-align: right;
                      padding-right: 12px;
                      color: #606266;
                      cursor: pointer;
                      white-space: nowrap;
                    ">
                    {{ enterpriseName
                    }}<i style="margin-left: 2px" class="el-icon-arrow-down">：</i>
                  </div>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="item" v-for="item in nameList" :key="item.id">{{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-input @keyup.enter.native="search" style="width: 180px" v-model="searchForm.companyName"
                placeholder="请输入企业名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item label-width="10px">
            <div style="white-space: nowrap">
              <el-button type="primary" size="medium" :disabled="loading" @click="search('searchForm')"
                icon="el-icon-search">搜索</el-button>
              <el-button type="primary" size="medium" :disabled="loading" icon="el-icon-delete"
                @click="resetForm('searchForm')">清空</el-button>
              <el-button type="primary" size="medium" :icon="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                @click="isShowSelect">更多选项</el-button>
            </div>
            <!-- <div class="showSelect" @click="isShowSelect">{{showSelect ? '隐藏筛选框' : '显示筛选框'}}</div> -->
          </el-form-item>
        </div>
        <!-- <el-form-item label="运单号：" prop="keyword" label-width="130px">
          <el-input v-model="searchForm.wayBillID" placeholder="请输入运单号">
          </el-input>
        </el-form-item> -->
        <el-collapse-transition>
          <div style="display: flex; flex-wrap: wrap" v-show="showSelect">
            <!-- <el-form-item label="收款人手机号：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.receivePhone"
                placeholder="请输入收款人手机号">
              </el-input>
            </el-form-item> -->
            <el-form-item label="车牌号：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px" v-model="searchForm.carNumber"
                placeholder="请输入车牌号">
              </el-input>
            </el-form-item>

            <el-form-item label="风控备注：" prop="Id" label-width="140px">
              <el-input style="width: 180px" v-model="searchForm.StrRemark" placeholder="请输入风控备注">
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="司机姓名：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.driverName"
                placeholder="请输入司机姓名">
              </el-input>
            </el-form-item>
            <el-form-item label="起始地：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.startAddress"
                placeholder="请输入起始地">
              </el-input>
            </el-form-item>
            <el-form-item label="到达地：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.arriveAddress"
                placeholder="请输入到达地">
              </el-input>
            </el-form-item> -->
            <el-form-item label="开票申请编号：" prop="keyword" label-width="140px">
              <el-input @keyup.enter.native="search" style="width: 180px" v-model="searchForm.settleNumber"
                placeholder="请输入开票申请编号">
              </el-input>
            </el-form-item>
            <el-form-item label="运单号：" prop="keyword" label-width="120px">
              <el-input @keyup.enter.native="search" style="width: 300px" v-model="searchForm.WaybillId"
                placeholder="请输入运单号">
                <el-select v-model="searchForm.waybillType" slot="prepend" placeholder="请选择单号类型" style="width: 100px">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="运单编号：" prop="Id" label-width="140px">
              <el-input style="width: 180px" v-model="searchForm.Id" placeholder="请输入运单编号">
              </el-input>
            </el-form-item> -->
            <el-form-item label="" prop="keyword" label-width="20px">
              <div style="display: flex">
                <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
                  <span class="el-dropdown-link">
                    <div style="
                        width: 108px;
                        text-align: right;
                        padding-right: 12px;
                        color: #606266;
                        cursor: pointer;
                        white-space: nowrap;
                      ">
                      {{ timeName
                      }}<i style="margin-left: 2px" class="el-icon-arrow-down">：</i>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="item in timeList" :key="item.id">{{ item.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-date-picker v-model="searchForm.time" type="daterange" start-placeholder="请选择开始时间"
                  end-placeholder="请选择结束时间" value-format="yyyy-MM-dd">
                </el-date-picker>
              </div>
              <!-- <div>签收时间：</div>
              <el-date-picker v-model="searchForm.time" type="datetimerange" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker> -->
            </el-form-item>
          </div>
        </el-collapse-transition>
      </el-form>
      <el-table :cell-style="timeStyle" :data="tableData" v-loading="loading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column fixed align="center" type="index" prop="index" label="序号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Id" label="运单编号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceCode" label="发票代码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceNumber" label="发票号" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="showInvoiceNumberHighlight(scope.row.InvoiceNumber)"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="服务商" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-show="searchForm.enterpriseId == 2" v-html="showSupplyNameHighlight(scope.row.EnterpriseFullName)">
            </div>
            <div v-show="searchForm.enterpriseId != 2">
              {{ scope.row.EnterpriseFullName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ImportTime" label="运单导入时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceApplyDatetime" label="开票申请时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayCompleteDatetime" label="确认支付时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ReceiptInvoiceTime" label="开票完成时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-show="searchForm.enterpriseId == 1" v-html="showNameHighlight(scope.row.GoodsOwnerName)"></div>
            <div v-show="searchForm.enterpriseId != 1">
              {{ scope.row.GoodsOwnerName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.DriverUserID)">
              <el-link type="primary">{{ scope.row.DriverName }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.DriverUserID)">
              <el-link type="primary">{{ scope.row.DriverPhone }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.DriverUserID)">
              <el-link type="primary">
                {{ scope.row.DriverIDCard }}
              </el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.PayeeUserID)">
              <el-link type="primary"> {{ scope.row.PayeeName }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.PayeeUserID)">
              <el-link type="primary">{{ scope.row.PayeePhone }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.PayeeUserID)">
              <el-link type="primary">{{ scope.row.PayeeIDCard }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.LeaderUserID)">
              <el-link type="primary">{{ scope.row.LeaderName }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.LeaderUserID)">
              <el-link type="primary">{{ scope.row.LeaderPhone }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.LeaderUserID)">
              <el-link type="primary">{{ scope.row.LeaderIDCard }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" label="车牌" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="showVehicleInfo(scope.row)">
              <el-link type="primary" v-html="showCarNumberHighlight(scope.row.CarNumber)"></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="VehicleTypeName" label="车辆类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoadNetWeight" label="装货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary" v-html="showOriginAddressHighlight(scope.row.OriginAddress)"></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>

        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary" v-html="showArriveAddressHighlight(scope.row.ArriveAddress)"></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>

        <el-table-column align="center" prop="DriverAllotCount" label="司机装货数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="司机运输单价（人民币）" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip align="center" prop="TecServiceFee" label="技术服务费/税费" width="200"
          v-if="$entrustTypeIds.includes(searchForm.type)">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TecServiceFee | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip v-if="$entrustTypeIds.includes(searchForm.type)" align="center"
          prop="EntrustedCollectionAmount" label="委托代开运费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedCollectionAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip v-if="$entrustTypeIds.includes(searchForm.type)" align="center"
          prop="ExpensesOfTaxation" label="委托代开服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TrustExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="$entrustTypeIds.includes(searchForm.type)" align="center" prop="TotalAmount"
          label="委托代开运单总金额" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TrustTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="TotalTransCost" label="运输总成本" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.TotalTransCost | formatMoney}}
            </div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column align="center" prop="TrailerNumber" label="挂车车牌" width="200" show-overflow-tooltip></el-table-column> -->
        <el-table-column align="center" prop="UnloadNetWeight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="OwnerContactsName" label="货主联系人" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="OwnerContactsPhone" label="货主联系人手机号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarrierName" label="承运商单位" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarrierContactsName" label="承运商联系人" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarrierContactsPhone" label="承运商联系人手机号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarriagePrice" label="承运运输单价（人民币）" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{scope.row.CarriagePrice | formatMoney}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="AllowLossRate" label="允许损耗率(‰)" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DrawnUnit" label="提货单位" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DrawnContactsPhone" label="提货联系人手机号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DrawnAddress" label="提货地址" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DrawnContactsName" label="提货联系人" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="TakeGoodsUnit" label="卸货单位" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="UnloadGoodsContactsPhone" label="卸货联系人手机号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="UnloadGoodsAddress" label="卸货地址" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="UnloadGoodsContactsName" label="卸货联系人" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="SignBillID" label="签收单号" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="HandworkBillID" label="手工单号" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="BillDatetime" label="单据时间" width="200" show-overflow-tooltip></el-table-column> -->
        <el-table-column align="center" prop="OriginalWayBillID" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="风控备注" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{ scope.row.Remark }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TaskStatus" label="运单状态" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.IsChargeback">
              <el-tag type="danger">已退款</el-tag>
            </div>
            <div v-else>
              <el-tag :type="scope.row.TaskStatus == 3 ? 'success' : 'danger'">
                {{ scope.row.TaskStatus == 3 ? "已开票" : "未开票" }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" prop="" label="操作" width="220">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" :loading="flag.openDetailLoading"
              @click="openVoucherDialog(scope.row)">详情</el-button>
            <el-button type="primary" size="mini" :loading="flag.openDetailLoading"
              @click="openPdmModel(scope.row)">打印运单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
        <!--数量金额总计-->
        <div style="display: flex; margin: 5px 0 0 30px">
          <div style="color: rgb(255, 139, 23); white-space: nowrap">
            {{
              $entrustTypeIds.includes(searchForm.type)
              ? "委托代开运费总计"
              : "运费总计"
            }}{{ totalData[0].YF | formatMoney }}
          </div>
          <div style="
              color: rgb(255, 139, 23);
              margin: 0 30px;
              white-space: nowrap;
            ">
            <span v-if="$entrustTypeIds.includes(searchForm.type)">委托代开</span>服务费/税费总计：{{ totalData[0].FWFSF | formatMoney
            }}
          </div>
          <div style="color: rgb(255, 139, 23); white-space: nowrap">
            <span v-if="$entrustTypeIds.includes(searchForm.type)">委托代开</span>运单总金额总计：{{ totalData[0].ZJE | formatMoney }}
          </div>
        </div>
      </div>
    </div>
    <!-- 上传凭证dialog -->
    <el-dialog width="1200px" class="dialog" title="详情" center :visible.sync="flag.voucherDialog" append-to-body
      v-if="flag.voucherDialog" :close-on-click-modal="false">
      <EvidenceInfo :businessID="currentItem.WaybillID" :isShowDetailInfo="true" :isShowFiveInfo="true"
        :currentItem="currentItem" :detailInfo="detailInfo">
      </EvidenceInfo>
    </el-dialog>
    <!--服务商/货主信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="supplyDialog" width="1000px" :close-on-click-modal="false">
      <span slot="title">{{ type == 1 ? "服务商信息" : "货主信息" }}</span>
      <div>
        <SupplyInfo :supplyId="supplyId"></SupplyInfo>
      </div>
    </el-dialog>
    <!--人员信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="personDialog" width="1300px" :close-on-click-modal="false">
      <span slot="title">人员信息</span>
      <DriverInfo :driverInfo="driverInfo" />
    </el-dialog>
    <!--显示车辆信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="vehicleDialog" width="1300px" :close-on-click-modal="false">
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
    </el-dialog>
    <!--查看轨迹-->
    <el-dialog width="800px" :visible.sync="traceDialog" append-to-body :close-on-click-modal="false"
      :close-on-press-escape="false" :destroy-on-close="true" class="deep_dialog" :before-close="closeTraceDialog">
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="traceDialog" ref="txMap" :list="mapList">
      </TXmap>
    </el-dialog>
    <!-- 预览 - 打印运单 -->
    <el-dialog :title="pdfModelTitle" :visible.sync="flag.isPreview" width="800px" custom-class="ydpdf-model"
      :close-on-click-modal="false" destroy-on-close>
      <YdpdfModel v-if="flag.isPreview" ref="ydpdfModel" :pdfModelTitle="pdfModelTitle" :printWaybillObj="printWaybillObj"
        :mapList="mapList"></YdpdfModel>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllBillList,
  allBillDwon,
  queryWayBillDetail,
  getPrintWaybill,
} from "@/api/waybill/otherAndAllBill/index";
import {
  getTypeAsync,
  getDriverDetails,
  getCarInfo,
  getMapPath,
} from "@/api/common/common";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
import SupplyInfo from "@/components/allWayBillCmpt/supplyInfoDialog";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import TXmap from "@/components/commonCmpt/TXmap";
import YdpdfModel from "@/components/businessCmpt/ydpdfModel";
import { _getBase64 } from "@/utils/utils";
let urlsObj = [
  {
    name: "司机证照",
    key: "DriverUrls",
  },
  {
    name: "车辆证照",
    key: "CarUrls",
  },
  {
    name: "运输凭证",
    key: "TransportUrls",
  },
  {
    name: "结算单凭证",
    key: "InvoiceTaskUrls",
  },
  {
    name: "资金凭证",
    key: "FundUrls",
  },
  {
    name: "合同凭证",
    key: "ContractUrls",
  },
  {
    name: "其他凭证",
    key: "OtherUrls",
  },
];
export default {
  data() {
    return {
      totalData: [
        {
          FWFSF: 0, // 服务费税费
          YF: 0, // 运费
          ZJE: 0, // 总金额
        },
      ], // 总计数据
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      options: [
        {
          value: "0",
          label: "运单号",
        },
        {
          value: "1",
          label: "客户单号",
        },
        {
          value: "2",
          label: "运单编号",
        },
      ],
      valuetype: "0",
      // 搜索表单
      searchForm: {
        StrRemark: "",//风控
        CarriageBillID: "", //客户单号
        companyName: "", // 企业名称
        driverPhone: "", // 司机手机号
        DriverIdCard: "", // 司机身份证号
        // driverName: '', // 司机姓名
        // receivePhone: '', // 收款人手机号
        carNumber: "", // 车牌
        WaybillId: "", // 运单号
        Id: null,
        time: ["", ""], // 时间
        type: "01", // 场景类型 默认为查找承运业务
        status: "-1", // 进度状态
        // startAddress: '', // 起始地
        // arriveAddress: '', // 到达地
        settleNumber: "", // 结算单号
        supplierName: "", // 服务商名称
        DateType: 1, // 时间类型
        enterpriseId: 1, // 货主名称选择类型id
        waybillType: "0", //单号类型
      },
      flag: {
        voucherDialog: false, //凭证dialog
        openDetailLoading: false, // 五流合一接口请求
        isPreview: false
      },
      tableData: [], //表格数据
      sceneList: [], //场景类型
      statusList: [
        {
          value: "-1",
          label: "全部",
        },
        {
          value: "1",
          label: "已通过风控",
        },
        {
          value: "2",
          label: "已申请结算",
        },
        {
          value: "3",
          label: "已同意开票",
        },
        {
          value: "4",
          label: "已确认付款",
        },
        {
          value: "5",
          label: "已上传发票",
        },
        {
          value: "6",
          label: "已完成运单流程",
        },
        {
          value: "7",
          label: "已退款",
        },
      ], // 进度状态
      // 是否禁用提交按钮
      showSubmit: false,
      loading: false,
      showSelect: false, // 控制是否显示筛选框
      // 时间选择列表
      timeList: [
        {
          id: 1,
          name: "签收时间",
        },
        {
          id: 2,
          name: "装货时间",
        },
        {
          id: 3,
          name: "运单导入时间",
        },
        {
          id: 4,
          name: "开票申请时间",
        },
        {
          id: 5,
          name: "确认支付时间",
        },
        {
          id: 6,
          name: "开票完成时间",
        },
      ],
      timeName: "签收时间",
      nameList: [
        {
          id: 1,
          name: "货主名称",
        },
        {
          id: 2,
          name: "服务商名称",
        },
      ], // 企业名称
      enterpriseName: "货主名称",
      // 各种详情弹框
      supplyDialog: false, // 服务商/货主信息弹框
      supplyId: "", // 服务商/货主id
      type: "", // 根据类型显示弹框 1是服务商 2是货主
      personDialog: false, // 人员信息弹框
      driverInfo: {}, //  司机 收款人信息
      vehicleInfo: {}, // 车辆信息
      vehicleDialog: false, // 车辆信息弹框
      traceDialog: false, // 查看轨迹组件
      // 轨迹列表
      mapList: [],
      currentItem: {}, // 当前点击列表按钮所选中的数据
      // 凭证结束
      detailInfo: {}, //五流合一详情信息
      pdfModelTitle: "",
      printWaybillObj: {},
    };
  },
  watch: {
    //解决签收时间清空后报错的bug
    "searchForm.time"(val) {
      if (!val) {
        this.searchForm.time = ["", ""];
      }
    },
  },
  methods: {
    // 打开预览pdf
    openPdmModel(item) {
      this.loading = true;

      this.currentItem = item;
      let { DriverName = "", CarNumber = "", WaybillID = "" } = item || {};
      this.pdfModelTitle = DriverName + "_" + CarNumber + "_" + WaybillID;

      Promise.all([
        this.openMap(item, false),
        this.getPrintWaybillObj(WaybillID),
      ]).finally(() => {
        setTimeout(() => {
          this.loading = false;
          localStorage.setItem("printWaybillObj", JSON.stringify(this.printWaybillObj))
          localStorage.setItem("pdfModelTitle", this.pdfModelTitle)
          localStorage.setItem("mapList", JSON.stringify(this.mapList))
          const href = this.$router.resolve({
            path: `/ydpdfModel`,
          }).href;
          window.open(href, "_blank");
          // this.flag.isPreview = true;
        }, 1000)
      });
    },
    async getPrintWaybillObj(WaybillID) {
      let res = await getPrintWaybill({ waybillId: WaybillID });
      if (res) {
        let saveObj = res.data
        // urlsObj.map((item) => {
        //   if (saveObj[item.key] && saveObj[item.key].length) {
        //     for (let i = 0; i < saveObj[item.key].length; i++) {
        //       let obj = saveObj[item.key][i]
        //       if (/\.(jpg|jpeg|png|gif)$/i.test(obj)) {
        //         _getBase64(obj).then((url) => {
        //           saveObj[item.key][i] = url
        //         });
        //       }
        //     }
        //   }
        // })
        this.printWaybillObj = {
          ...saveObj,
          waybillId: WaybillID,
        };
      }
    },
    //打开上传凭证弹窗
    openVoucherDialog(item) {
      this.currentItem = item;
      let params = {
        waybillId: item.WaybillID,
      };
      this.flag.openDetailLoading = true;
      queryWayBillDetail(params)
        .then((res) => {
          res.data.Vouchers.forEach((item, index) => {
            item.Name = `付款凭证${index + 1}`;
          });
          this.detailInfo = res.data;
          this.flag.voucherDialog = true;
          this.flag.openDetailLoading = false;
        })
        .catch(() => {
          this.flag.voucherDialog = false;
          this.flag.openDetailLoading = false;
        });
    },
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect;
    },
    //搜索
    search(formName) {
      if (this.searchForm.Id) {
        if (!(/^\d+$/.test(this.searchForm.Id))) {
          this.$message.warning('运单编号只能为数字！')
          return
        }
        if (String(this.searchForm.Id).length > 8) {
          this.$message.warning('运单编号长度不能大于8位！')
          return
        }
      }
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //搜索前初始化page
      this.pagination.page = 1;
      this.loading = true;
      this.getAllBillList();
      this.totalData = [
        {
          FWFSF: 0, // 服务费税费
          YF: 0, // 运费
          ZJE: 0, // 总金额
        },
      ]; // 总计数据
      //   }
      //   })
    },
    //清空
    resetForm(formName) {
      this.loading = true;
      this.totalData = [
        {
          FWFSF: 0, // 服务费税费
          YF: 0, // 运费
          ZJE: 0, // 总金额
        },
      ]; // 总计数据
      this.searchForm = {
        StrRemark: '',
        companyName: "", // 企业名称
        driverPhone: "", // 司机手机号
        DriverIdCard: "", // 司机身份证号
        // driverName: '',
        // receivePhone: '', // 收款人手机号
        carNumber: "", // 车牌
        WaybillId: "", // 运单号
        Id: null,
        time: ["", ""], // 时间
        type: "01", // 场景类型 默认为查找承运业务
        status: "-1", // 进度状态
        // startAddress: '', // 起始地
        // arriveAddress: '', // 到达地
        settleNumber: "", // 结算单号
        supplierName: "", // 服务商名称
        DateType: this.searchForm.DateType,
        enterpriseId: 1,
        waybillType: "0",
      };
      this.enterpriseName = "全部";
      this.pagination.page = 1;
      this.getAllBillList();
    },
    //导出excel
    exportExcel() {
      let params = {
        taskType: this.searchForm.type,
        wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        DriverIdCard: this.searchForm.DriverIdCard, // 司机身份证号
        // driverName: this.searchForm.driverName,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        // originAddress: this.searchForm.startAddress,
        // arriveAddress: this.searchForm.arriveAddress,
        WaybillId: this.searchForm.WaybillId,
        Id: this.searchForm.Id,
        // payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        StrRemark: this.searchForm.StrRemark,
        importTimeStart: this.searchForm.time ? this.searchForm.time[0] : "",
        importTimeEnd: this.searchForm.time ? this.searchForm.time[1] : "",
        // SupplierName: this.searchForm.supplierName,
        InvoiceTaskID: this.searchForm.settleNumber,
        DateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId,
        ManualStatus: this.searchForm.waybillType, //单号类型
      };
      allBillDwon(params).then((res) => {
        // window.location.href = res.data
        this.$notify({
          title: "下载提示",
          message: res.data,
          position: "top-right",
          type: "success",
        });
      });
    },
    //tbale选中改变
    tableSelectionChange(e) { },
    // 切换场景类型
    changeType(val) {
      this.pagination.page = 1;
      this.tableData = [];
      this.totalData = [
        {
          FWFSF: 0, // 服务费税费
          YF: 0, // 运费
          ZJE: 0, // 总金额
        },
      ]; // 总计数据
      // this.searchForm.type = val
      this.search();
    },
    // 切换进度状态
    changeProgress(val) {
      this.searchForm.status = val;
      this.search();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.getAllBillList();
    },
    //获取所有运单列表
    getAllBillList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        taskType: this.searchForm.type,
        wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        DriverIdCard: this.searchForm.DriverIdCard, // 司机身份证号
        // driverName: this.searchForm.driverName,
        carNumber: this.searchForm.carNumber,
        WaybillId: this.searchForm.WaybillId,
        Id: this.searchForm.Id,
        StrRemark: this.searchForm.StrRemark,
        goodsOwnerName: this.searchForm.companyName,
        // originAddress: this.searchForm.startAddress,
        // arriveAddress: this.searchForm.arriveAddress,
        // payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time ? this.searchForm.time[0] : "",
        importTimeEnd: this.searchForm.time ? this.searchForm.time[1] : "",
        // SupplierName: this.searchForm.supplierName,
        InvoiceTaskID: this.searchForm.settleNumber,
        DateType: this.searchForm.DateType,
        roleType: this.searchForm.enterpriseId,
        ManualStatus: this.searchForm.waybillType, //单号类型
      };
      this.loading = true;
      getAllBillList(params)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.totalData = JSON.parse(res.data.Extend);
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //获取场景类型列表
    getSceneList() {
      getTypeAsync(27).then((res) => {
        this.sceneList = res.data;
      });
    },
    // 选择时间下拉菜单
    handleCommand(val) {
      this.timeName = val.name;
      this.searchForm.DateType = val.id;
      this.search();
    },
    // 货主名称下拉
    handleCommandName(val) {
      this.enterpriseName = val.name;
      this.searchForm.enterpriseId = val.id;
      this.search();
    },
    // 显示各种弹框
    // 显示服务商/货主信息弹框
    showSupplyDialog(scope, index) {
      this.type = index;
      if (index == 1) {
        // 服务商信息
        this.supplyId = scope.row.PrincipalCompany;
      } else if (index == 2) {
        // 货主信息
        this.supplyId = scope.row.UserID;
      }
      this.supplyDialog = true;
    },
    // 查询人员信息
    getPersonInfo(item, UserID) {
      let data = {};
      getDriverDetails({ UserID, AscriptionUserID: item.UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.personDialog = true;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 获取车辆信息
    showVehicleInfo(item) {
      let getVehicleInfoed = false; //是否拿到了车子信息
      let getCarInfoed = false; //是否拿到了车子证件照
      let params = {}; // 车辆信息
      getVehicleInfoed = true;
      let params2 = {
        CarNumber: item.CarNumber,
      };
      getCarInfo(params2)
        .then((res) => {
          //   params = Object.assign(params, res.data.vehicle_baseInfo);
          // if (res.data.vehicle_confirmInfo) {
          //       params.VehicleLicenseFrontPageURL =
          //         res.data.vehicle_confirmInfo.VehicleLicenseFrontPageURL; //车辆行驶证（正页）
          //       params.VehicleLicenseSubPageBackURL =
          //         res.data.vehicle_confirmInfo.VehicleLicenseSubPageBackURL; //车辆行驶证（副页反面照）
          //       params.VehicleLicenseSubPageOnURL =
          //         res.data.vehicle_confirmInfo.VehicleLicenseSubPageOnURL; //车辆行驶证（副页正面照）
          //       params.VehicleRTPUTL = res.data.vehicle_confirmInfo.VehicleRTPUTL; // 车辆道路运输证照
          //       getCarInfoed = true;
          //     } else {
          //   this.$message.error("暂无该车辆数据，请稍后查询");
          // }

          this.vehicleInfo = Object.assign(
            params,
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          getCarInfoed = true;
        })
        .finally(() => {
          if (getVehicleInfoed && getCarInfoed) {
            this.vehicleDialog = true;
          }
        });
    },
    //打开地图
    openMap(item, openModel = true) {
      this.loading = true;
      let params = {
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
        wayBillId: item.WaybillID,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          if (openModel) this.traceDialog = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 货主名称搜索高亮
    showNameHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.companyName) !== -1 &&
          this.searchForm.companyName !== ""
        ) {
          return val.replace(
            this.searchForm.companyName,
            '<font color="red">' + this.searchForm.companyName + "</font>"
          );
        } else {
          return val;
        }
      } else {
        return val;
      }
    },
    // 服务商名称高亮
    showSupplyNameHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.companyName) !== -1 &&
          this.searchForm.companyName !== ""
        ) {
          return val.replace(
            this.searchForm.companyName,
            '<font color="red">' + this.searchForm.companyName + "</font>"
          );
        } else {
          return val;
        }
      } else {
        return val;
      }
    },
    // 收款人手机号高亮
    showPhoneHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.receivePhone) !== -1 &&
          this.searchForm.receivePhone !== ""
        ) {
          return val.replace(
            this.searchForm.receivePhone,
            '<font color="red">' + this.searchForm.receivePhone + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 司机手机号高亮
    showDriverPhoneHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.driverPhone) !== -1 &&
          this.searchForm.driverPhone !== ""
        ) {
          return val.replace(
            this.searchForm.driverPhone,
            '<font color="red">' + this.searchForm.driverPhone + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 车牌号高亮
    showCarNumberHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.carNumber) !== -1 &&
          this.searchForm.carNumber !== ""
        ) {
          return val.replace(
            this.searchForm.carNumber,
            '<font color="red">' + this.searchForm.carNumber + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 司机手机号高亮
    showDriverNameHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.driverName) !== -1 &&
          this.searchForm.driverName !== ""
        ) {
          return val.replace(
            this.searchForm.driverName,
            '<font color="red">' + this.searchForm.driverName + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 起始地高亮
    showOriginAddressHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.startAddress) !== -1 &&
          this.searchForm.startAddress !== ""
        ) {
          return val.replace(
            this.searchForm.startAddress,
            '<font color="red">' + this.searchForm.startAddress + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 到达地高亮
    showArriveAddressHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.arriveAddress) !== -1 &&
          this.searchForm.arriveAddress !== ""
        ) {
          return val.replace(
            this.searchForm.arriveAddress,
            '<font color="red">' + this.searchForm.arriveAddress + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 结算单号高亮
    showInvoiceNumberHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.settleNumber) !== -1 &&
          this.searchForm.settleNumber != ""
        ) {
          return val.replace(
            this.searchForm.settleNumber,
            '<font color="red">' + this.searchForm.settleNumber + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 时间筛选高亮 timeStyle
    timeStyle(column) {
      if (this.searchForm.DateType == 1) {
        if (
          column.column.property == "SignDatetime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 2) {
        if (
          column.column.property == "LoadingDatetime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 3) {
        if (
          column.column.property == "ImportTime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 4) {
        if (
          column.column.property == "InvoiceApplyDatetime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 5) {
        if (
          column.column.property == "PayCompleteDatetime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      } else if (this.searchForm.DateType == 6) {
        if (
          column.column.property == "ReceiptInvoiceTime" &&
          this.searchForm.time && this.searchForm.time[0] != ''
        ) {
          return "color: red";
        }
      }
    },
    // 关闭查看轨迹 弹框
    closeTraceDialog() {
      this.$nextTick(() => {
        this.$refs.txMap.destroyElement();
      });
      this.traceDialog = false;
    },
  },
  created() {
    //获取全部运单列表
    this.getAllBillList();
    //获取场景类型列表
    this.getSceneList();
  },
  components: {
    EvidenceInfo,
    SupplyInfo,
    DriverInfo,
    VehicleInfo,
    TXmap,
    YdpdfModel,
  },
};
</script>

<style lang="scss">
.allBillPage {
  ::v-deep .roleDialog {
    .el-dialog__body {
      display: flex;
      justify-content: center;
    }

    .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    .el-dialog__body {
      padding: 20px;
    }

    .infos {
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 16px;
      width: 420px;
      display: flex;
      justify-content: flex-start;

      .el-form-item {
        width: 420px;
        margin-bottom: 0;
      }

      .el-form-item__label {
        text-align: left;
        color: #333333;
      }

      .el-input__inner {
        width: 330px;
        border: none;
      }
    }

    .info {
      margin-bottom: 16px;
      width: 420px;
      font-size: 14px;
      padding-left: 6px;

      .el-form-item {
        margin-bottom: 0;
      }

      .el-textarea {
        width: 420px !important;
      }

      .el-textarea__inner {
        height: 80px;
      }

      .name {
        margin-bottom: 8px;
        margin-left: 10px;
      }

      .el-form-item__error {
        left: 14px;
      }

      .el-input__inner {
        border: none;
      }
    }
  }
}

.dialog .el-dialog {
  top: -10%;
}
</style>

<style scoped lang="scss">
.allBillPage {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .showSelect {
    line-height: 40px;
    height: 40px;
    margin-left: 20px;
    color: #606266;
    cursor: pointer;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}
</style>
